import Vue from 'vue'
import Vuex from 'vuex'
// import 'firebase/compat/auth'
import {
  getFirestore,
  Timestamp,
  doc,
  addDoc,
  setDoc,
  collection,
  deleteDoc,
  onSnapshot,
  query,
  where,
  collectionGroup,
} from '@firebase/firestore'
import {
  getAuth,
  signOut,
  signInWithRedirect,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
} from '@firebase/auth'
import {
  UserData,
  PermissionData,
  ActionFeedback,
  ActionFeedbackResult,
  ActionFeedbackState,
  EmailListData,
  BuyerListData,
} from '@/models/interfaces'
// import { CalendarTimestamp } from 'vuetify'

Vue.use(Vuex)

const dbUnsubscribes: (() => void)[] = [] // An array of functions that when called, unsub the data stream

export default new Vuex.Store({
  state: (): {
    user: UserData | null
    userPermissions: PermissionData | null
    users: UserData[]
    usersPermissions: PermissionData[]
    actionFeedback: ActionFeedback | null
    emailListsOwn: EmailListData[]
    emailListsForSale: EmailListData[]
    emailListsAdmin: EmailListData[]
    buyerListsOwn: EmailListData[]
    buyerListsAdmin: EmailListData[]
  } => ({
    user: null,
    userPermissions: null,
    users: [],
    usersPermissions: [],
    actionFeedback: null,
    emailListsOwn: [],
    emailListsForSale: [],
    emailListsAdmin: [],
    buyerListsOwn: [],
    buyerListsAdmin: [],
  }),
  getters: {
    getUser: (state) => {
      return state.user
    },
    getUserPermissions: (state) => {
      return state.userPermissions
    },
    getUsers: (state) => {
      return state.users
    },
    getUserById: (state) => (userId: string) => {
      return state.users.find((user: UserData) => user.id === userId)
    },
    getUsersPermissions: (state) => {
      return state.usersPermissions
    },
    userIsMember: (state) => {
      return state.userPermissions
        ? state.userPermissions.role === 'member'
        : false
    },
    userIsAdmin: (state) => {
      return state.userPermissions
        ? state.userPermissions.role === 'admin'
        : false
    },
    getActionFeedback: (state) => {
      return state.actionFeedback
    },
    getEmailListsOwn: (state) => {
      return state.emailListsOwn
    },
    getEmailListsForSale: (state) => {
      return state.emailListsForSale
    },
    getEmailListsAdmin: (state) => {
      return state.emailListsAdmin
    },
    getBuyerListsOwn: (state) => {
      return state.buyerListsOwn
    },
    getBuyerListsAdmin: (state) => {
      return state.buyerListsAdmin
    },
    getEmailListByIdAdmin: (state) => (listId: string) => {
      return state.emailListsAdmin.find(
        (list: EmailListData) => list.id === listId
      )
    },
  },
  mutations: {
    setUser: (state, userObj) => {
      Vue.set(state, 'user', userObj)
    },
    setUserPermissions: (state, userPermissionsObj) => {
      Vue.set(state, 'userPermissions', userPermissionsObj)
    },
    setUsers: (state, users) => {
      Vue.set(state, 'users', users)
    },
    setUsersPermissions: (state, permissions) => {
      Vue.set(state, 'usersPermissions', permissions)
    },
    setActionFeedback: (state, actionFeedbackObject) => {
      Vue.set(state, 'actionFeedback', {
        type: actionFeedbackObject.type,
        result: actionFeedbackObject.result,
      })
    },
    setEmailListsOwn: (state, emailLists) => {
      Vue.set(state, 'emailListsOwn', emailLists)
    },
    setEmailListsForSale: (state, emailLists) => {
      Vue.set(state, 'emailListsForSale', emailLists)
    },
    setEmailListsAdmin: (state, emailLists) => {
      Vue.set(state, 'emailListsAdmin', emailLists)
    },
    setAdminViewUserId: (state, adminViewUserId: string) => {
      Vue.set(state, 'adminViewUserId', adminViewUserId)
    },
    setBuyerListsOwn: (state, buyerLists) => {
      Vue.set(state, 'buyerListsOwn', buyerLists)
    },
    setBuyerListsAdmin: (state, buyerLists) => {
      Vue.set(state, 'buyerListsAdmin', buyerLists)
    },
  },
  actions: {
    logInUser: ({ commit }) => {
      const auth = getAuth()
      const provider = new GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account',
      })
      signInWithRedirect(auth, provider)
        .then(() => {
          // Can pass "result" as variable via .then( (result) => {
          // The signed-in user info.
          // const user = result.user
          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = result.credential.accessToken
          // Log in functions handled in autoLogInUser via main.ts which watches for auth changes
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Success,
            messages: [error.code, error.message],
          }
          commit('setActionFeedback', {
            type: 'logInUserWithGoogleAuth',
            result: result,
          })
        })
    },
    logInUserWithEmailLink: ({ commit }, email) => {
      const auth = getAuth()
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: process.env.VUE_APP_SITE_URL + '/login',
        // This must be true.
        handleCodeInApp: true,
      }
      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email)
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Success,
            messages: ['Check your email (' + email + ') for log-in link'],
          }
          commit('setActionFeedback', {
            type: 'logInUserWithEmailLink',
            result: result,
          })
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.code, error.message],
          }
          commit('setActionFeedback', {
            type: 'logInUserWithEmailLink',
            result: result,
          })
        })
    },
    autoLogInUser: async ({ commit, dispatch }, payload) => {
      //try to log in
      try {
        if (payload.emailVerified !== true) {
          throw new Error('Email not yet verified')
        }

        const user: UserData = {
          displayName: payload.displayName,
          photoURL: payload.photoURL,
          email: payload.email,
          lastLogin: Timestamp.now(),
        }

        user.id = payload.uid
        commit('setUser', user) // set here so that it may be used in bindings below

        const firestore = getFirestore()
        // add or update user in db
        const userRef = doc(firestore, 'users', payload.uid)
        await setDoc(userRef, user, { merge: true })

        // do a data load for the user
        dispatch('bindUserToDb')
        dispatch('bindUserPermissionsToDb')

        // data bindings go here...
        // currently no other data bindings needed
      } catch (error) {
        console.log('Error logging in')
        if (error instanceof Error) {
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.name, error.message],
          }
          commit('setActionFeedback', { type: 'autoLogInUser', result: result })
        }
      }
    },
    logOutUser: () => {
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          // Log out functions handled via main.ts which calls autoLogOutUser
        })
        .catch(function (error) {
          // An error happened.
          console.log('Error', error)
        })
    },
    autoLogOutUser: ({ commit }) => {
      dbUnsubscribes.forEach((unsubscribe) => {
        unsubscribe() // Calling the listener unsubscribes user from data stream
      })
      commit('setUser', null)
      commit('setUserPermissions', null)
      commit('setEmailListsOwn', [])
      commit('setEmailListsForSale', [])
      commit('setEmailListsAdmin', [])
      commit('setBuyerListsOwn', [])
      commit('setBuyerListsAdmin', [])

      const result: ActionFeedbackResult = {
        state: ActionFeedbackState.Success,
        messages: ['You have successfully logged out'],
      }
      commit('setActionFeedback', { type: 'logOutUser', result: result })
    },
    updateUserPermission: async ({ getters, commit }, editedUserPayload) => {
      try {
        if (getters.getUserPermissions.role != 'admin') {
          throw new Error('Permission denied')
        }
        const firestore = getFirestore()
        const permissionsRef = doc(
          firestore,
          'userPermissions',
          editedUserPayload.user.id
        )
        await setDoc(
          permissionsRef,
          { role: editedUserPayload.permissions.role },
          { merge: true }
        )
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Success,
          messages: ['User permission updated'],
        }
        commit('setActionFeedback', {
          type: 'updateUserPermission',
          result: result,
        })
      } catch (error) {
        if (error instanceof Error) {
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.name, error.message],
          }
          commit('setActionFeedback', {
            type: 'updateUserPermission',
            result: result,
          })
        }
      }
    },
    updateActionFeedback: ({ commit }, payload: ActionFeedback) => {
      commit('setActionFeedback', {
        type: payload.type,
        result: payload.result,
      })
    },
    resetActionFeedback: ({ commit }, payload: ActionFeedback) => {
      commit('setActionFeedback', { type: payload, result: null })
    },
    updateAdminViewUserId: ({ commit }, payload: string) => {
      commit('setAdminViewUserId', payload)
    },
    addEmailList: async ({ getters, commit }, payload) => {
      try {
        //validation
        if (!payload.name || !payload.description || !payload.pricePerName) {
          throw new Error('Missing required email list properties')
        }
        if (!payload.agreement) {
          throw new Error('Must agree to terms')
        }
        if (!getters.getUser) {
          throw new Error('No user detected')
        }

        const newEmailList: EmailListData = {
          name: payload.name,
          description: payload.description,
          pricePerName: payload.pricePerName,
          fileName: '',
          tags: payload.tags,
          timestampCreated: Timestamp.now(),
          timestampUpdated: Timestamp.now(),
          userId: getters.getUser.id,
          published: payload.published,
          approved: false,
          ownerTrashed: false,
          agreement: payload.agreement,
        }
        //update the db
        const firestore = getFirestore()
        const newEmailListRef = await addDoc(
          collection(firestore, 'emailLists'),
          newEmailList
        )
        if (newEmailListRef.id) {
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Success,
            messages: [
              'Email list added to the market, pending review by our administrative team.',
            ],
          }
          commit('setActionFeedback', {
            type: 'emailListCreate',
            result: result,
          })
          return newEmailListRef.id
        }
      } catch (error) {
        if (error instanceof Error) {
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.name, error.message],
          }
          commit('setActionFeedback', {
            type: 'emailListCreate',
            result: result,
          })
          return null
        }
      }
    },
    updateEmailList: async ({ getters, commit }, payload) => {
      try {
        const newProps = payload.emailListNewProps // for list properties
        const newFileProps = payload.newFileProps // for file associated with list

        // validation
        if (!getters.getUser) {
          throw new Error('No logged-in user detected')
        }
        if (
          getters.getUser.id !== newProps.userId &&
          getters.getUserPermissions.role !== 'admin'
        ) {
          throw new Error('Email list update access denied')
        }

        let updatedList = {
          timestampUpdated: Timestamp.now(),
        }
        if (newProps.name !== undefined) {
          updatedList = { ...updatedList, ...{ name: newProps.name } }
        }
        if (newProps.description !== undefined) {
          updatedList = {
            ...updatedList,
            ...{ description: newProps.description },
          }
        }
        if (newProps.pricePerName !== undefined) {
          updatedList = {
            ...updatedList,
            ...{ pricePerName: newProps.pricePerName },
          }
        }
        if (newProps.fileName !== undefined) {
          updatedList = { ...updatedList, ...{ fileName: newProps.fileName } }
        }
        if (newProps.tags !== undefined) {
          updatedList = { ...updatedList, ...{ tags: newProps.tags } }
        }
        if (newProps.published !== undefined) {
          updatedList = { ...updatedList, ...{ published: newProps.published } }
        }
        if (newFileProps && newFileProps.stateColumn !== undefined) {
          updatedList = {
            ...updatedList,
            ...{ stateColumn: newFileProps.stateColumn },
          }
        }

        //update the db
        const firestore = getFirestore()
        const emailListRef = doc(firestore, 'emailLists', newProps.id)
        await setDoc(emailListRef, updatedList, { merge: true })
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Success,
          messages: ['Email list updated'],
        }
        commit('setActionFeedback', {
          type: 'emailListUpdate',
          result: result,
        })
      } catch (error) {
        if (error instanceof Error) {
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.name, error.message],
          }
          commit('setActionFeedback', {
            type: 'emailListUpdate',
            result: result,
          })
        }
      }
    },
    updateEmailListApproved: async ({ getters, commit }, payload) => {
      try {
        // validation
        if (!getters.getUser) {
          throw new Error('No logged-in user detected')
        }
        if (
          !getters.getUserPermissions ||
          getters.getUserPermissions.role !== 'admin'
        ) {
          throw new Error('Email list update approved access denied')
        }

        // update firebase
        const updateEmailList = {
          approved: payload.approved,
        }
        const firestore = getFirestore()
        const emailListRef = doc(firestore, 'emailLists', payload.id)
        await setDoc(emailListRef, updateEmailList, { merge: true })
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Success,
          messages: ['Email list approval updated'],
        }
        commit('setActionFeedback', {
          type: 'emailListUpdateApproval',
          result: result,
        })
      } catch (error) {
        if (error instanceof Error) {
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.name, error.message],
          }
          commit('setActionFeedback', {
            type: 'emailListUpdateApproval',
            result: result,
          })
        }
      }
    },
    updateEmailListTrashed: async ({ getters, commit }, payload) => {
      try {
        // validation
        if (!getters.getUser) {
          throw new Error('No logged-in user detected')
        }
        // update firebase
        const updateEmailList = {
          ownerTrashed: payload.ownerTrashed,
        }
        const firestore = getFirestore()
        const emailListRef = doc(firestore, 'emailLists', payload.id)
        await setDoc(emailListRef, updateEmailList, { merge: true })
        const successMessage = payload.ownerTrashed
          ? 'Email list moved to trash'
          : 'Email list removed from trash'
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Success,
          messages: [successMessage],
        }
        commit('setActionFeedback', {
          type: 'emailListUpdateTrashed',
          result: result,
        })
      } catch (error) {
        if (error instanceof Error) {
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.name, error.message],
          }
          commit('setActionFeedback', {
            type: 'emailListUpdateTrashed',
            result: result,
          })
        }
      }
    },
    deleteEmailList: async ({ getters, commit }, emailListId) => {
      try {
        //validation
        if (!emailListId) {
          throw new Error('Missing email list id')
        }
        if (!getters.getUser) {
          throw new Error('No user detected')
        }
        const firestore = getFirestore()
        const emailListRef = doc(firestore, 'emailLists', emailListId)
        await deleteDoc(emailListRef)
        const result: ActionFeedbackResult = {
          state: ActionFeedbackState.Success,
          messages: ['EmailList deleted'],
        }
        commit('setActionFeedback', {
          type: 'deleteEmailList',
          result: result,
        })
      } catch (error) {
        if (error instanceof Error) {
          const result: ActionFeedbackResult = {
            state: ActionFeedbackState.Error,
            messages: [error.name, error.message],
          }
          commit('setActionFeedback', {
            type: 'deleteEmailList',
            result: result,
          })
        }
      }
    },
    bindUserToDb: ({ commit, getters }) => {
      const userId = getters.getUser.id
      try {
        if (!userId) {
          throw new Error('Unable to determine user Id')
        }
        const firestore = getFirestore()
        const listener = onSnapshot(doc(firestore, 'users', userId), (doc) => {
          commit('setUser', { id: doc.id, ...doc.data() })
          dbUnsubscribes.push(listener) // Add listener to unsubscribes for unsubscribing later (probably on logout)
        })
      } catch (error) {
        console.log(error)
      }
    },
    bindUserPermissionsToDb: ({ commit, getters }) => {
      const userId = getters.getUser.id
      try {
        if (!userId) {
          throw new Error('Unable to determine user Id')
        }
        const firestore = getFirestore()
        const listener = onSnapshot(
          doc(firestore, 'userPermissions', userId),
          (doc) => {
            commit('setUserPermissions', { id: doc.id, ...doc.data() })
            dbUnsubscribes.push(listener) // Add listener to unsubscribes for unsubscribing later (probably on logout)
          }
        )
      } catch (error) {
        console.log(error)
      }
    },
    bindUsersToDb: ({ getters, commit }) => {
      try {
        if (
          getters.getUserPermissions &&
          getters.getUserPermissions.role !== 'admin'
        ) {
          throw new Error('Permission denied')
        }
        const firestore = getFirestore()
        const q = query(collection(firestore, 'users'))
        const listener = onSnapshot(q, (querySnapshot) => {
          const items: UserData[] = []
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            const user: UserData = {
              id: doc.id,
              displayName: data.displayName,
              photoURL: data.photoURL,
              email: data.email,
              lastLogin: data.lastLogin,
            }
            items.push(user)
          })

          //now commit the items to Vuex state
          commit('setUsers', items)

          //add listener to unsubscribes for unsubscribing later (probably on logout)
          dbUnsubscribes.push(listener)
        })
      } catch (error) {
        //handle error
        console.log(error)
      }
    },
    bindUsersPermissionsToDb: ({ getters, commit }) => {
      try {
        if (
          getters.getUserPermissions &&
          getters.getUserPermissions.role !== 'admin'
        ) {
          throw new Error('Permission denied')
        }
        const firestore = getFirestore()
        const q = query(collection(firestore, 'userPermissions'))
        const listener = onSnapshot(q, (querySnapshot) => {
          const items: PermissionData[] = []
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            const permission: PermissionData = {
              id: doc.id,
              role: data.role,
            }
            items.push(permission)
          })

          //now commit the items to Vuex state
          commit('setUsersPermissions', items)

          //add listener to unsubscribes for unsubscribing later (probably on logout)
          dbUnsubscribes.push(listener)
        })
      } catch (error) {
        //handle error
        console.log(error)
      }
    },
    bindEmailListsToDb: ({ getters, commit }, payload) => {
      // payload.viewSet options:
      // 'ownLists' // whether or not we are only loading the user's lists
      // 'forSale' // if true, get all published and approved lists
      // 'admin' // get all lists
      const viewSet = payload.viewSet
      try {
        if (viewSet === 'ownList' && !getters.getUser) {
          throw new Error('You are not logged in')
        }
        if (
          viewSet === 'forSale' &&
          !(
            getters.getUserPermissions &&
            getters.getUserPermissions.role &&
            (getters.getUserPermissions.role === 'member' ||
              getters.getUserPermissions.role === 'admin')
          )
        ) {
          throw new Error('Permission to email lists denied')
        }
        if (
          viewSet === 'admin' &&
          !(getters.getUser && getters.getUserPermissions.role === 'admin')
        ) {
          throw new Error('Permission to admin email lists denied')
        }
        const firestore = getFirestore()
        let q = query(collection(firestore, 'emailLists'))
        if (viewSet === 'ownLists' && getters.getUser) {
          q = query(
            collection(firestore, 'emailLists'),
            where('userId', '==', getters.getUser.id)
          )
        } else if (viewSet === 'forSale') {
          q = query(
            collection(firestore, 'emailLists'),
            where('approved', '==', true),
            where('published', '==', true),
            where('userId', '!=', getters.getUser.id)
          )
        } else if (
          viewSet === 'admin' &&
          getters.getUserPermissions &&
          getters.getUserPermissions.role === 'admin'
        ) {
          // do not change from default, get all email lists
        } else {
          throw new Error('Unspecified list type')
        }
        const listener = onSnapshot(
          q,
          (querySnapshot) => {
            const items: EmailListData[] = []
            querySnapshot.forEach((doc) => {
              const data = doc.data()
              const emailList: EmailListData = {
                id: doc.id,
                name: data.name,
                count: data.count ? data.count : 0,
                stateCount: data.stateCount ? data.stateCount : null,
                fileProcessing: data.fileProcessing
                  ? data.fileProcessing
                  : false,
                description: data.description,
                pricePerName: data.pricePerName,
                fileName: data.fileName,
                tags: data.tags,
                timestampCreated: data.timestampCreated,
                timestampUpdated: data.timestampUpdated,
                timestampProcessed: data.timestampProcessed
                  ? data.timestampProcessed
                  : null,
                userId: data.userId,
                published: data.published,
                approved: data.approved,
                ownerTrashed: data.ownerTrashed,
                agreement: data.agreement,
              }
              items.push(emailList)
            })
            items.sort((a, b) => {
              return a['timestampUpdated'] > b['timestampUpdated'] ? 1 : -1
            })
            items.reverse()

            //now commit the items to their respective Vuex state vars
            if (viewSet === 'ownLists') {
              commit('setEmailListsOwn', items)
            }
            if (viewSet === 'forSale') {
              commit('setEmailListsForSale', items)
            }
            if (viewSet === 'admin') {
              commit('setEmailListsAdmin', items)
            }

            //add listener to unsubscribes for unsubscribing later (probably on logout)
            dbUnsubscribes.push(listener)
          },
          (error) => {
            throw new Error(error.message)
          }
        )
      } catch (error) {
        //handle error
        console.log(error)
      }
    },
    bindBuyerListsToDb: ({ getters, commit }, payload) => {
      const viewSet = payload.viewSet
      try {
        if (!getters.getUser) {
          throw new Error('No user found')
        }
        if (
          viewSet === 'own' &&
          !(
            getters.getUserPermissions &&
            getters.getUserPermissions.role &&
            (getters.getUserPermissions.role === 'member' ||
              getters.getUserPermissions.role === 'admin')
          )
        ) {
          throw new Error('Permission denied')
        }
        if (
          viewSet === 'admin' &&
          !(getters.getUser && getters.getUserPermissions.role === 'admin')
        ) {
          throw new Error('Permission to admin email lists denied')
        }
        const firestore = getFirestore()
        let q = query(collectionGroup(firestore, 'buyerLists'))
        if (viewSet === 'own') {
          q = query(
            collectionGroup(firestore, 'buyerLists'),
            where('buyerUserId', '==', getters.getUser.id)
          )
        } else if (
          viewSet === 'admin' &&
          getters.getUserPermissions &&
          getters.getUserPermissions.role === 'admin'
        ) {
          // do not change from default, get all email lists
        } else {
          throw new Error('Unspecified list type')
        }
        const listener = onSnapshot(q, (querySnapshot) => {
          const items: BuyerListData[] = []
          querySnapshot.forEach((doc) => {
            const data = doc.data()
            const buyerList: BuyerListData = {
              id: doc.id,
              sellerListId: data.sellerListId,
              buyerUserId: data.buyerUserId,
              timestampUpdated: data.timestampUpdated,
              stateSelection: data.stateSelection,
              newNames: data.newNames,
              buyerListFileName: data.buyerListFileName,
              buyerListLength: data.buyerListLength,
              fileProcessing: data.fileProcessing,
            }
            items.push(buyerList)
          })

          //now commit the items to Vuex state
          //now commit the items to their respective Vuex state vars
          if (viewSet === 'own') {
            commit('setBuyerListsOwn', items)
          }
          if (viewSet === 'admin') {
            commit('setBuyerListsAdmin', items)
          }

          //add listener to unsubscribes for unsubscribing later (probably on logout)
          dbUnsubscribes.push(listener)
        })
      } catch (error) {
        //handle error
        console.log(error)
      }
    },
  },
  modules: {},
})
