


































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'App',
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getUserPermissions']),
    items() {
      let items: {
        title: string
        icon: string
        route: string
        subitems?: { title: string; route: string }[]
      }[] = []
      items = [
        {
          title: 'Buy a List',
          icon: 'mdi-cart-outline',
          route: '/buy',
        },
        {
          title: 'Sell a List',
          icon: 'mdi-currency-usd',
          route: '/yourlists',
        },
      ]
      if (this.getUserPermissions && this.getUserPermissions.role === 'admin') {
        items.push({
          title: 'System',
          icon: 'mdi-laptop',
          route: '/system-admin',
        })
      }
      return items
    },
  },
  methods: {
    logOutClick() {
      // this.logOutUser() // executes on logout page
      this.$router.push('logout').catch((error) => {
        console.log(error)
      }) //catch error otherwise throws error in console; error is usually because user is already on the page
    },
  },
})
