import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

function lazyLoad(view: string) {
  return () => import(`@/views/${view}.vue`)
}

const routes: Array<RouteConfig> = [
  {
    path: '/buy',
    alias: '/',
    name: 'BuyEmailLists',
    component: lazyLoad('BuyEmailLists'),
    meta: { requiresAuth: true },
  },
  {
    path: '/sell',
    name: 'SellEmailLists',
    component: lazyLoad('SellEmailLists'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/yourlists',
        name: 'YourLists',
        component: lazyLoad('YourLists'),
        meta: { requiresAuth: true },
      },
      {
        path: '/addlist',
        name: 'AddList',
        component: lazyLoad('AddList'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/system-admin',
    name: 'SystemAdmin',
    component: lazyLoad('SystemAdmin'),
    meta: { requiresAuth: true, systemAdminOnly: true },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: lazyLoad('LoginPage'),
  },
  {
    path: '/logout',
    name: 'LogoutPage',
    component: lazyLoad('LogoutPage'),
  },
  {
    path: '/account',
    name: 'Account',
    component: lazyLoad('Account'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.requiresAuth && !store.getters.getUser) {
    // if view is behind auth, but no auth'd user
    next({ name: 'LoginPage' })
  } else if (
    to.meta &&
    to.meta.systemAdminOnly &&
    store.getters.getUserPermissions &&
    store.getters.getUserPermissions.role !== 'admin'
  ) {
    // if view is admin only, but not admin role
    next({ name: 'LoginPage' })
  } else if (to.name === 'LoginPage' && store.getters.getUser) {
    // if trying to access login, but already logged in
    next({ name: 'Account' })
  } else {
    next()
  }
})

export default router
