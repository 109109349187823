import { Timestamp } from '@firebase/firestore'

// interfaces for front-end user feedback
export const enum ActionFeedbackState {
  Success = 'success',
  Error = 'error',
}
export interface ActionFeedbackResult {
  state: ActionFeedbackState
  messages: string[]
}
export interface ActionFeedback {
  type: string
  result: ActionFeedbackResult
}
// end interfaces for front-end user feedback

// TypeScript interfaces for database objects
export interface UserData {
  id?: string
  displayName: string
  photoURL: string
  email: string
  lastLogin: Timestamp
}
export const enum PermissionRole {
  Admin = 'admin',
  Member = 'member',
  Unverified = 'unverified',
}
export interface PermissionData {
  id: string
  role: PermissionRole
}
export interface EmailListData {
  id?: string
  timestampCreated: Timestamp
  timestampUpdated: Timestamp
  timestampProcessed?: Timestamp
  userId: string
  name: string
  count?: number
  stateCount?: {
    [key: string]: number
  }
  fileProcessing?: boolean
  description: string
  pricePerName: number
  fileName: string
  tags: string[]
  published: boolean
  approved: boolean
  ownerTrashed: boolean
  agreement: boolean
}
export interface BuyerListData {
  id?: string
  timestampUpdated: Timestamp
  sellerListId: string
  buyerUserId: string
  stateSelection?: string
  fileProcessing?: boolean
  buyerListFileName?: string
  buyerListLength?: number
  newNames?: number
}
// end interfaces for database objects

// interfaces for front-end list handling objects
export interface EmailListToEdit {
  id: string
  userId: string
  name?: string
  count?: number
  description?: string
  pricePerName?: number
  fileName?: string
  tags?: string[]
  published?: boolean
  ownerTrashed?: boolean
}

export interface FilePreview {
  headers: string[]
  body: unknown[]
  errors: string[]
  count: number
  isValid: boolean
}

export interface NewFileProps {
  zipColumn: boolean
}
// end front-end list handling objects
