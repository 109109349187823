// src/plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      // options: { customProperties: true },
      light: {
        primary: colors.indigo.lighten1,
        secondary: colors.green.lighten1,
        accent: colors.deepPurple.lighten1,
        aside: colors.blueGrey.lighten5,
        highlight: colors.yellow.lighten5,
        danger: colors.red.darken1,
      },
    },
  },
})
