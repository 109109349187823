import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'

import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC4IEC9PgY-CqLvXto-DnIQLaiz67_1rQc',
  authDomain: process.env.VUE_APP_SITE_DOMAIN,
  projectId: 'listbetter-app',
  storageBucket: 'listbetter-app',
  messagingSenderId: '605622214965',
  appId: '1:605622214965:web:72900f44642bff4c90ec7c',
  measurementId: 'G-BJWWPZJ3Q3',
}

Vue.config.productionTip = false

new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  render: (h) => h(App),
  created() {
    // Initialize Firebase
    const firebaseApp = initializeApp(firebaseConfig)
    const auth = getAuth(firebaseApp)
    if (process.env.NODE_ENV === 'development') {
      console.log('DEV MODE')
      const functions = getFunctions(firebaseApp)
      const firestore = getFirestore(firebaseApp)
      const storageApp = getStorage(firebaseApp) // uses default bucket as defined in firebaseConfig
      const storageDownloads = getStorage(firebaseApp, 'listbetter-downloads')
      connectFunctionsEmulator(functions, 'localhost', 5001)
      connectFirestoreEmulator(firestore, 'localhost', 8088)
      connectAuthEmulator(auth, 'http://localhost:9099')
      connectStorageEmulator(storageApp, 'localhost', 9199)
      connectStorageEmulator(storageDownloads, 'localhost', 9199)
    }
    const analytics = getAnalytics()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.dispatch('autoLogInUser', user)
        logEvent(analytics, 'login')
      } else {
        this.$store.dispatch('autoLogOutUser')
      }
    })
  },
})
